// src/components/Home.tsx
import React from 'react';
import SearchBar, {SearchParams} from './page-structure/SearchBar';
import RecipeList from './recipes/RecipeList';
import {Recipe} from '../types';
import {mockRecipes} from '../mockRecipes';
import {useNavigate} from 'react-router-dom';
import {API_BASE_URL} from '../apiConfig';
import {useTranslation} from 'react-i18next';


const Home: React.FC = () => {
  const [recipes, setRecipes] = React.useState<Recipe[]>([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const lang = (i18n.language?.split('-')[0]) || 'en';

  //todo: mock server
  const handleSearch = (searchParams: SearchParams) => {
    setRecipes(mockRecipes)
  };

  const fetchRandomRecipe = async () => {
    // Replace with your API URL
    const response = await fetch(`${API_BASE_URL}/recipes/random?lang=${lang}`);
    const data = await response.json();
    setRecipes([data]);
    // Redirect to the RecipeDetails route
    navigate(`/recipes/${data.id}`, {state: {recipe: data}});
  };

  return (
    <>
      <SearchBar onSearch={handleSearch}/>
      <RecipeList recipes={recipes}/>
      <button onClick={fetchRandomRecipe}>{t('fetchRandomRecipe')}</button>
    </>
  );
};

export default Home;
