import React from 'react';
import './Footer.css';
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from 'react-router-dom';

const Footer: React.FC = () => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);

    // Update the query parameter in the URL without refreshing the page
    const params = new URLSearchParams(location.search);
    params.set('lang', lang);
    navigate({ search: params.toString() });
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>{t('quickAccess')}</h4>
          <div className="footer-links">
            <div className="footer-column">
              <h5>{t('recipes')}</h5>
              <ul>
                <li><a href="#">{t('vegetarianRecipes')}</a></li>
                <li><a href="#">{t('dessertRecipes')}</a></li>
                <li><a href="#">{t('bakingRecipes')}</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <h5>{t('learn')}</h5>
              <ul>
                <li><a href="#">{t('howTos')}</a></li>
                <li><a href="#">{t('seasonalCalendar')}</a></li>
                <li><a href="#">{t('avoidFoodWaste')}</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <h5>{t('community')}</h5>
              <ul>
                <li><a href="#">{t('stories')}</a></li>
                <li><a href="#">{t('community')}</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <h5>APPS</h5>
              <ul>
                <li><a href="#">Android</a></li>
                <li><a href="#">iOS</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-middle">
          <div className="footer-column">
            <div className="social-media">
              <p><strong>{t('socialMedia')}</strong></p>
              <div className="social-icons">
                <a href="#"><i className="fab fa-facebook-f"></i></a>
                <a href="#"><i className="fab fa-instagram"></i></a>
                <a href="#"><i className="fab fa-youtube"></i></a>
                <a href="#"><i className="fab fa-pinterest"></i></a>
                <a href="#"><i className="fab fa-tiktok"></i></a>
              </div>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-meta">
              <p><strong>{t('languageSelection')}</strong></p>
              <div className="languages">
                <a href="?lang=en" onClick={(e) => {
                  e.preventDefault();
                  changeLanguage('en');
                }}>English</a>|
                <a href="?lang=de" onClick={(e) => {
                  e.preventDefault();
                  changeLanguage('de');
                }}>Deutsch</a>|
                <a href="?lang=fr" onClick={(e) => {
                  e.preventDefault();
                  changeLanguage('fr');
                }}>Français</a>|
                <a href="?lang=it" onClick={(e) => {
                  e.preventDefault();
                  changeLanguage('it');
                }}>Italiano</a>|
                <a href="?lang=es" onClick={(e) => {
                  e.preventDefault();
                  changeLanguage('es');
                }}>Español</a>|
                <a href="?lang=ru" onClick={(e) => {
                  e.preventDefault();
                  changeLanguage('ru');
                }}>Русский</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="terms-conditions">
            <a href="#">Terms & Conditions</a> | <a href="#">Data protection</a> | <a href="#">Imprint</a>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
