import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import './App.css';
import AppRoutes from "./Routes";
import Footer from "./components/page-structure/Footer";
import Header from "./components/page-structure/Header";

function App() {
  const location = useLocation();
  const {i18n} = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lang = params.get('lang');
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.dir(i18n.language);

    if (lang && (i18n.language?.split('-')[0]) !== lang) {
      i18n.changeLanguage(lang); // Change language globally
    }

  }, [location, i18n, i18n.language]);

  return (
    <div className="App">
      <Header/>
      <AppRoutes/>
      <Footer/>
    </div>
  );
}

export default App;