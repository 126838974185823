import React, {useState} from 'react';
import Logo from './Logo';
import {useTranslation} from 'react-i18next';
import './Header.css';
import {Link} from "react-router-dom";

const Header: React.FC = () => {
  const {t} = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  // Toggle main menu open/close
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setActiveDropdown(null); // Close submenus when main menu is toggled
  };

  // Toggle individual submenu
  const toggleSubMenu = (menuName: string) => {
    setActiveDropdown(activeDropdown === menuName ? null : menuName);
  };

  return (
    <header className="header">
      <nav className="header-nav">
        {/* Hamburger icon for mobile screens */}
        <div className="header-hamburger">
          <div className="header-hamburger-menu" onClick={toggleMenu}>
            ☰
          </div>
          <div className="header-hamburger-logo">
            <Link to="/">
              <Logo size="small"/>
            </Link>
          </div>
        </div>


        {/* Menu (links and actions) */}
        <div className={`header-menu ${menuOpen ? 'open' : ''}`}>
          {/* Left section with links */}
          <div className="header-links">
            <div className={`header-link-group ${activeDropdown === 'recipes' ? 'active' : ''}`}>
              <span className="header-link" onClick={() => toggleSubMenu('recipes')}>
                {t('recipes')} <span className="arrow-down">▼</span>
              </span>
              <ul className="dropdown">
                <li><a href="#">{t('vegetarianRecipes')}</a></li>
                <li><a href="#">{t('dessertRecipes')}</a></li>
                <li><a href="#">{t('bakingRecipes')}</a></li>
              </ul>
            </div>
            <div className={`header-link-group ${activeDropdown === 'learn' ? 'active' : ''}`}>
              <span className="header-link" onClick={() => toggleSubMenu('learn')}>
                {t('learn')} <span className="arrow-down">▼</span>
              </span>
              <ul className="dropdown">
                <li><a href="#">{t('howTos')}</a></li>
                <li><a href="#">{t('seasonalCalendar')}</a></li>
                <li><a href="#">{t('avoidFoodWaste')}</a></li>
              </ul>
            </div>
            <div className={`header-link-group ${activeDropdown === 'community' ? 'active' : ''}`}>
              <span className="header-link" onClick={() => toggleSubMenu('community')}>
                {t('community')} <span className="arrow-down">▼</span>
              </span>
              <ul className="dropdown">
                <li><a href="#">{t('stories')}</a></li>
                <li><a href="#">{t('community')}</a></li>
              </ul>
            </div>
          </div>


          {/* Center logo */}
          {!menuOpen && (
            <div className="header-logo">
              <Link to="/">
                <Logo size="large"/>
              </Link>
            </div>
          )
          }

          {/* Right section with "My Space" and search */}
          <div className="header-actions">
            <a href="#" className="header-action">{t('mySpace')}</a>
            <div className="header-search-icon" style={{cursor: 'pointer'}}>
              <span className="search-icon">&#x1F50D;</span>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
