// src/components/RecipeDetails.tsx
import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {Recipe} from '../../types';
import './RecipeDetails.css';
import {FaStar} from 'react-icons/fa';
import {API_BASE_URL} from '../../apiConfig';
import {useTranslation} from "react-i18next";

interface RouteParams {
  id?: string;
}

interface RecipeDetailsProps {
  recipe: Recipe | null;
}

const RecipeDetails: React.FC<RecipeDetailsProps> = ({recipe: propRecipe}) => {
  const {id} = useParams() as RouteParams;
  const location = useLocation() as { state: { recipe: Recipe } };
  const [recipe, setRecipe] = useState<Recipe | null>(location.state?.recipe || propRecipe);
  const {t, i18n} = useTranslation();
  const lang = (i18n.language?.split('-')[0]) || 'en';

  const prevLangRef = useRef<string>(lang);

  useEffect(() => {
    if (!recipe || lang !== prevLangRef.current) {

      const fetchRecipe = async () => {
        const response = await fetch(`${API_BASE_URL}/recipes/${id}?lang=${lang}`);
        const data = await response.json();
        setRecipe(data);
      };

      fetchRecipe();
      prevLangRef.current = lang;
    }
  }, [id, recipe, lang]);

  // Handle the case where id might be undefined
  if (!id) {
    return (
      <div className="recipe-details">
        <p>{t('missingRecipe')}</p>
        <Link to="/" className="back-button">← {t('backToRecipes')}</Link>
      </div>
    );
  }

  if (!recipe) {
    return (
      <div className="recipe-details">
        <p>{t('recipeNotFound')}</p>
        <Link to="/" className="back-button">← {t('backToRecipes')}</Link>
      </div>
    );
  }

  const {
    name,
    author,
    description,
    image,
    prepTime,
    cookTime,
    totalTime,
    servings,
    servingSize,
    difficulty,
    rating,
    ingredientsDetailed,
    steps,
    equipment,
    cuisine,
    dietaryRestrictions,
    allergens,
    caloricBreakdown,
    tips,
  } = recipe;

  return (
    <div className="recipe-details">
      <Link to="/" className="back-button">← {t('backToRecipes')}</Link>
      <h2 className="recipe-name">{name}</h2>
      <p className="recipe-author">{t('by')} {author}</p>
      <img src={image} alt={name} className="recipe-details-image"/>
      <div className="recipe-meta">
        <div>
          <strong>{t('difficulty')}:</strong> {difficulty}
        </div>
        <div>
          <strong>{t('servings')}:</strong> {servings}
        </div>

        {servingSize && (
          <div>
            <strong>{t('servingSize')}:</strong> {servingSize}
          </div>
        )}
        {prepTime && (
          <div>
            <strong>{t('prepTime')}:</strong> {prepTime}
          </div>
        )}
        {cookTime && (
          <div>
            <strong>{t('cookTime')}:</strong> {cookTime}
          </div>
        )}
        <div>
          <strong>{t('totalTime')}:</strong> {totalTime}
        </div>
        <div>
          <strong>{t('cuisine')}:</strong> {cuisine}
        </div>
      </div>

      <div className="recipe-rating">
        <RatingStars rating={rating}/>
        <span>{(rating ?? 0).toFixed(1)} / 5</span>
      </div>

      <p className="recipe-description">{description}</p>

      {/* Adjusted section headers and content alignment */}
      {/* 1) Caloric Breakdown (always on own line) */}
      <section className="recipe-section caloric-breakdown-section">
        <h3 className="section-title">{t('caloricBreakdown')}</h3>
        <ul className="caloric-breakdown">
          <li>
            <strong>{t('calories')}:</strong> {caloricBreakdown?.calories ?? caloricBreakdown?.caloriesPerServing ?? 'N/A'} kcal
          </li>
          <li>
            <strong>{t('carbs')}:</strong> {caloricBreakdown?.carbsPercentage ?? 'N/A'}%
          </li>
          <li>
            <strong>{t('fat')}:</strong> {caloricBreakdown?.fatPercentage ?? 'N/A'}%
          </li>
          <li>
            <strong>{t('protein')}:</strong> {caloricBreakdown?.proteinPercentage ?? 'N/A'}%
          </li>
        </ul>
      </section>
      {/* 2) Ingredients */}
      <div className="ingredients-equipment-container">
        <section className="recipe-section ingredients-section">
          <h3 className="section-title">{t('ingredients')}</h3>
          <ul className="ingredients-list">
            {ingredientsDetailed.map((ingredient, index) => (
              <li key={index}>
                {ingredient.quantity} {ingredient.name}
              </li>
            ))}
          </ul>
        </section>

        {/* 3) Equipment (can be on same line as Caloric Breakdown) */}
        {/* We'll group Equipment and Caloric Breakdown in a container */}
        {/* But since Caloric Breakdown is always on its own line, we won't place them together */}
        {/* Instead, we can group Equipment with Ingredients if that's acceptable */}
        <section className="recipe-section equipment-section">
          <h3 className="section-title">{t('equipment')}</h3>
          <ul className="equipment-list">
            {equipment.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </section>
      </div>

      {/* 4) Steps (always on own line) */}
      <section className="recipe-section  steps-section">
        <h3 className="section-title">{t('steps')}</h3>
        <ol className="steps-list">
          {steps.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ol>
      </section>

      {/* 5) Tips (always on own line) */}
      {tips.length > 0 && (
        <section className="recipe-section tips-section">
          <h3 className="section-title">{t('tips')}</h3>
          <ul className="tips-list">
            {tips.map((tip, index) => (
              <li key={index}>{tip}</li>
            ))}
          </ul>
        </section>
      )}

      {/* 6) Dietary Restrictions */}
      <div className="dietary-allergens-container">
        {dietaryRestrictions.length > 0 && (
          <section className="recipe-section dietary-allergens-section">
            <h3 className="section-title">{t('dietaryRestrictions')}</h3>
            <ul className="dietary-list">
              {dietaryRestrictions.map((diet, index) => (
                <li key={index}>{diet}</li>
              ))}
            </ul>
          </section>
        )}

        {/* 7) Allergens (can be on same line as Dietary Restrictions) */}
        {allergens.length > 0 && (
          <section className="recipe-section dietary-allergens-section">
            <h3 className="section-title">{t('allergens')}</h3>
            <ul className="allergens-list">
              {allergens.map((allergen, index) => (
                <li key={index}>{allergen}</li>
              ))}
            </ul>
          </section>
        )}
      </div>
    </div>
  );
};

interface RatingStarsProps {
  rating: number;
}

const RatingStars: React.FC<RatingStarsProps> = ({rating}) => {
  // Same as in RecipeCard
  const fullStars = Math.floor(rating);
  const halfStar = rating - fullStars >= 0.5;

  return (
    <div className="rating-stars">
      {[...Array(5)].map((_, index) => {
        if (index < fullStars) {
          return <FaStar key={index} color="#ffc107"/>;
        } else if (index === fullStars && halfStar) {
          return <FaStar key={index} color="#ffc107" style={{clipPath: 'inset(0 50% 0 0)'}}/>;
        } else {
          return <FaStar key={index} color="#e4e5e9"/>;
        }
      })}
    </div>
  );
};

export default RecipeDetails;
