// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import ru from './locales/ru/translation.json';
// Import other languages as needed

i18n
.use(LanguageDetector) // Detects user language
.use(initReactI18next) // Passes i18n down to react-i18next
.init({
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
    ru: {
      translation: ru,
    },
    // Add other languages
  },
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React already escapes from XSS
  },
});

export default i18n;
