// src/mockRecipes.ts
import { Recipe } from './types';

export const mockRecipes: Recipe[] = [
  {
    id: 1,
    name: 'Spaghetti Bolognese',
    author: 'Chef Luigi',
    description:
      'A classic Italian pasta dish with rich, meaty sauce served over tender spaghetti.',
    image: 'https://via.placeholder.com/400x300?text=Spaghetti+Bolognese',
    prepTime: '15 mins',
    cookTime: '45 mins',
    totalTime: '1 hr',
    servings: 4,
    servingSize: '1 bowl',
    difficulty: 'Medium',
    rating: 4.5,
    userRatings: [],
    ingredients: ['Spaghetti', 'Tomato Sauce', 'Ground Beef', 'Onions', 'Garlic'],
    ingredientsDetailed: [
      { name: 'Spaghetti', quantity: '400g' },
      { name: 'Tomato Sauce', quantity: '800g' },
      { name: 'Ground Beef', quantity: '500g' },
      { name: 'Onions', quantity: '1 large, chopped' },
      { name: 'Garlic', quantity: '3 cloves, minced' },
    ],
    steps: [
      'Cook spaghetti according to package instructions.',
      'In a separate pan, sauté onions and garlic until translucent.',
      'Add ground beef and cook until browned.',
      'Stir in tomato sauce and simmer for 30 minutes.',
      'Serve sauce over spaghetti.',
    ],
    stepImages: [],
    equipment: ['Pot', 'Pan', 'Stirring Spoon'],
    cuisine: 'Italian',
    mealType: ['Dinner'],
    dietaryRestrictions: ['Contains Gluten'],
    allergens: ['Wheat'],
    cookingMethod: 'Boiling',
    caloricBreakdown: { calories: 400, carbsPercentage: 50, fatPercentage: 30, proteinPercentage: 20 },
    tips: ['For extra flavor, add fresh basil leaves.'],
    estimatedCost: '$15',
    videoUrl: '',
    sourceUrl: '',
    dateAdded: '2023-01-01',
    isSeasonal: false,
    popularityScore: 80,
    keywords: ['pasta', 'bolognese'],
    similarRecipes: [2],
    tags: ['Pasta', 'Comfort Food'],
  },
  {
    id: 2,
    name: 'Vegetable Stir Fry',
    author: 'Chef Mei',
    description:
      'A quick and healthy vegetable stir fry with a flavorful sauce, perfect for a weeknight dinner.',
    image: 'https://via.placeholder.com/400x300?text=Vegetable+Stir+Fry',
    prepTime: '10 mins',
    cookTime: '15 mins',
    totalTime: '25 mins',
    servings: 2,
    servingSize: '1 plate',
    difficulty: 'Easy',
    rating: 4.8,
    userRatings: [],
    ingredients: ['Broccoli', 'Carrots', 'Bell Peppers', 'Soy Sauce', 'Garlic', 'Ginger'],
    ingredientsDetailed: [
      { name: 'Broccoli', quantity: '1 cup, chopped' },
      { name: 'Carrots', quantity: '1 cup, sliced' },
      { name: 'Bell Peppers', quantity: '1 cup, sliced' },
      { name: 'Soy Sauce', quantity: '2 tbsp' },
      { name: 'Garlic', quantity: '2 cloves, minced' },
      { name: 'Ginger', quantity: '1 tsp, grated' },
    ],
    steps: [
      'Heat oil in a wok over high heat.',
      'Add garlic and ginger, stir for 30 seconds.',
      'Add vegetables and stir fry for 5-7 minutes.',
      'Pour in soy sauce and cook for another 2 minutes.',
      'Serve hot over rice or noodles.',
    ],
    stepImages: [],
    equipment: ['Wok', 'Spatula'],
    cuisine: 'Chinese',
    mealType: ['Dinner', 'Lunch'],
    dietaryRestrictions: ['Vegan', 'Gluten-Free'],
    allergens: ['Soy'],
    cookingMethod: 'Stir-Frying',
    caloricBreakdown: { calories: 300, carbsPercentage: 60, fatPercentage: 20, proteinPercentage: 20 },
    tips: ['Add tofu or chicken for extra protein if desired.'],
    estimatedCost: '$10',
    videoUrl: '',
    sourceUrl: '',
    dateAdded: '2023-01-05',
    isSeasonal: false,
    popularityScore: 90,
    keywords: ['stir fry', 'vegetarian', 'quick meal'],
    similarRecipes: [1],
    tags: ['Vegetarian', 'Quick', 'Healthy'],
  },
];
