// src/components/RecipeList.tsx
import React from 'react';
import RecipeCard from './RecipeCard';
import {Recipe} from '../../types';
import './RecipeList.css';
import {useTranslation} from "react-i18next";

interface RecipeListProps {
  recipes: Recipe[];
}

const RecipeList: React.FC<RecipeListProps> = ({recipes}) => {
  const {t} = useTranslation();
  if (!recipes.length) {
    return <p>{t('noRecipesFound')}</p>;
  }

  return (
    <div className="recipe-list">
      {recipes.map((recipe) => (
        <RecipeCard key={recipe.id} recipe={recipe}/>
      ))}
    </div>
  );
};

export default RecipeList;
