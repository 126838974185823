// src/components/RecipeCard.tsx
import React from 'react';
import {Recipe} from '../../types';
import {Link} from 'react-router-dom';
import './RecipeCard.css';
import {FaStar} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

interface RecipeCardProps {
  recipe: Recipe;
}

const RecipeCard: React.FC<RecipeCardProps> = ({recipe}) => {
  const {t} = useTranslation();
  const {
    name,
    image,
    author,
    difficulty,
    rating,
    servings,
    prepTime,
    cookTime,
    caloricBreakdown,
    totalTime,
    cuisine,
    dietaryRestrictions,
    tags,
  } = recipe;

  return (
    <Link to={`/recipes/${recipe.id}`} className="recipe-card-link">
      <div className="recipe-card">
        <img src={image} alt={`${name}`} className="recipe-image"/>
        <div className="recipe-content">
          <h2 className="recipe-title">{name}</h2>
          <p className="recipe-author">{t('by')} {author}</p>
          <div className="recipe-rating">
            <RatingStars rating={rating}/>
            <span>{(rating ?? 0).toFixed(1)} / 5</span>
          </div>
          <div className="recipe-details">
            <div>
              <strong>{t('difficulty')}:</strong> {difficulty}
            </div>
            <div>
              <strong>{t('servings')}:</strong> {servings}
            </div>
            <div>
              <strong>{t('prepTime')}:</strong> {prepTime}
            </div>
            <div>
              <strong>{t('cookTime')}:</strong> {cookTime}
            </div>
            <div>
              <strong>{t('totalTime')}:</strong> {totalTime}
            </div>
            <div className="recipe-calories">
              <strong>{t('calories')}:</strong> {caloricBreakdown?.calories ?? caloricBreakdown?.caloriesPerServing ?? 'N/A'} kcal
            </div>
            <div>
              <strong>{t('cuisine')}:</strong> {cuisine}
            </div>
          </div>
          {tags && (
            <div className="recipe-tags">
              {tags.map((tag, index) => (
                <span key={index} className="recipe-tag">
              {tag}
            </span>
              ))}
            </div>
          )}
          <div className="recipe-dietary">
            {dietaryRestrictions.map((diet, index) => (
              <span key={index} className="dietary-tag">
              {diet}
            </span>
            ))}
          </div>
          {/* Additional fields can be displayed as needed */}
        </div>
      </div>
    </Link>
  );
};

interface RatingStarsProps {
  rating: number;
}

const RatingStars: React.FC<RatingStarsProps> = ({rating}) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating - fullStars >= 0.5;

  return (
    <div className="rating-stars">
      {[...Array(5)].map((_, index) => {
        if (index < fullStars) {
          return <FaStar key={index} color="#ffc107"/>;
        } else if (index === fullStars && halfStar) {
          return <FaStar key={index} color="#ffc107" style={{clipPath: 'inset(0 50% 0 0)'}}/>;
        } else {
          return <FaStar key={index} color="#e4e5e9"/>;
        }
      })}
    </div>
  );
};

export default RecipeCard;
