// Logo.tsx
import React from 'react';

interface LogoProps {
  size: 'small' | 'large';
}

const Logo: React.FC<LogoProps> = ({ size }) => {
  return <img
    src={`${process.env.PUBLIC_URL}/logo.png`} // Path to logo image in public folder
    alt="Cook Anything"
    style={{ width: size === 'small' ? '60px' : '150px', height: 'auto', borderRadius: '30%', padding: 0}}
  />;
};

export default Logo;
