// src/components/SearchBar.tsx
import React, {useState} from 'react';
import './SearchBar.css';
import {useTranslation} from "react-i18next";

export interface SearchParams {
  name: string;
  tags: string[];
  ingredients: string[];
}

interface SearchBarProps {
  onSearch: (searchParams: SearchParams) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({onSearch}) => {
  const {t} = useTranslation();
  const [name, setName] = useState('');
  const [tags, setTags] = useState('');
  const [ingredients, setIngredients] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchParams: SearchParams = {
      name,
      tags: tags ? tags.split(',').map((tag) => tag.trim()) : [],
      ingredients: ingredients ? ingredients.split(',').map((ingredient) => ingredient.trim()) : [],
    };
    onSearch(searchParams);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">{t('recipeName')}:</label>
        <input
          id="name"
          type="text"
          value={name}
          placeholder={t('recipeNamePlaceholder')}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="tags">{t('tags')}:</label>
        <input
          id="tags"
          type="text"
          value={tags}
          placeholder={t('tagsPlaceholder')}
          onChange={(e) => setTags(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="ingredients">{t('ingredients')}:</label>
        <input
          id="ingredients"
          type="text"
          value={ingredients}
          placeholder={t('ingredientsPlaceholder')}
          onChange={(e) => setIngredients(e.target.value)}
        />
      </div>
      <button type="submit">{t('searchRecipes')}</button>
    </form>
  );
};

export default SearchBar;
